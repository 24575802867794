
import { Vue, Component } from 'vue-property-decorator'
import { appName } from '@/env'

import SurveyPublic from '@/components/SurveyPublic.vue'
import { ISurvey, IResponseFull } from '@/interfaces/survey'
import surveyModule from '@/store/survey'
import responseModule from '@/store/response'

@Component({
  components: {
    SurveyPublic
  }
})
export default class PublicSurveyView extends Vue {
  appName = appName
  isLoading = true
  surveyData: ISurvey | null = null
  reviewResponseData: IResponseFull | null = null

  async mounted() {
    await surveyModule.actionLoadPublicById(this.uuid)

    this.surveyData = surveyModule.itemById(this.uuid) || null

    if (this.surveyData === undefined) {
      this.$router.push({ name: 'hello-page' })
      return
    }

    if (this.responseId) {
      this.reviewResponseData =
        (await responseModule.actionGetResponseById({
          surveyId: this.surveyData.id!,
          responseId: this.responseId
        })) || null
    }

    this.isLoading = false
  }

  get uuid() {
    return this.$route.params.uuid
  }

  get responseId() {
    return this.$route.params.responseId
  }
}
