
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Survey } from 'survey-vue-ui'
import { StylesManager, Model, PageModel, SurveyElement } from 'survey-core'
import showdown from 'showdown'

import 'survey-core/modern.min.css'

import { v4 as uuidv4 } from 'uuid'

import { IResponseFull, ISurvey } from '@/interfaces/survey'
import responseModule from '@/store/response'

const UUID_LOCALSTORAGE_KEY = 'mb-survey-uuid'
const DATA_LOCALSTORAGE_KEY = 'mb-survey-data'

StylesManager.applyTheme('modern')

type SurveyModelType = any // TODO:

@Component({ components: { Survey } })
export default class SurveyPublic extends Vue {
  @Prop() surveyData!: ISurvey
  @Prop() reviewData!: IResponseFull | null

  survey: SurveyModelType = null
  lastChangedQuestionId: string | null = null

  constructor() {
    super()

    const defaultThemeColors = StylesManager.ThemeColors['modern']

    // https://github.com/surveyjs/survey-library/blob/master/src/modern/constants.scss
    defaultThemeColors['$main-color'] = '#000000'
    defaultThemeColors['$text-color'] = '#4a4a4a'
    defaultThemeColors['$answer-background-color'] = '#61616129'

    // console.log(defaultThemeColors)
    // defaultThemeColors['$main-hover-color'] = '#6fe06f'
    // defaultThemeColors['$header-color'] = '#7ff07f'

    StylesManager.applyTheme('modern')

    let data = this.surveyData.data_json || {}
    if (this.reviewData) {
      // Do not use paginating for review
      // Show everything in one page
      data = { ...data, questionsOnPageMode: 'singlePage' }
    }

    this.survey = new (Model as any)(data)

    // Add markdown support
    const converter = new showdown.Converter()
    this.survey.onTextMarkdown.add(
      (
        survey: Model,
        options: { element: typeof SurveyElement; text: string; name: string; html: string }
      ) => {
        if (options.element instanceof PageModel) return

        //convert the markdown text to html
        let str = converter.makeHtml(options.text)
        //remove root paragraphs <p></p>
        str = str.substring(3)
        str = str.substring(0, str.length - 4)
        //set html
        options.html = str
      }
    )

    this.survey.onAfterRenderPage.add((survey: Model, options: any) => {
      // Scroll to the top of the page
      this.scrollToTop()
    })

    this.survey.onValueChanging.add((survey: Model, options: any) => {
      this.lastChangedQuestionId = options.question.id
    })

    this.survey.onVisibleChanged.add((survey: Model, options: any) => {
      if (this.lastChangedQuestionId) {
        const el = document.querySelector(`#${this.lastChangedQuestionId}`)
        if (el) {
          setTimeout(() => {
            // el.scrollIntoView({ behavior: 'smooth', block: 'center' })
            document.location.href = '#' + this.lastChangedQuestionId
          }, 100)
        }
      }
    })
  }

  mounted() {
    if (this.reviewData) {
      this.survey.data = this.reviewData.data
      this.survey.mode = 'display' //set a survey to read-only mode
    } else {
      // Get UUID from localStorage
      let uuid = localStorage.getItem(UUID_LOCALSTORAGE_KEY)

      if (!uuid) {
        // Generate UUID
        uuid = uuidv4()
        localStorage.setItem(UUID_LOCALSTORAGE_KEY, uuid)
      }

      // Load data from previous session
      var prevData = localStorage.getItem(DATA_LOCALSTORAGE_KEY) || null
      if (prevData) {
        var data = JSON.parse(prevData)
        this.survey.data = data
        if (data.pageNo) {
          this.survey.currentPageNo = data.pageNo
        }
      }

      this.survey.sendResultOnPageNext = true

      this.survey.onComplete.add((survey: SurveyModelType) => {
        this.sendDataToServer(uuid!, survey, true)

        // Delete UUID from localStorage
        localStorage.removeItem(UUID_LOCALSTORAGE_KEY)
        localStorage.removeItem(DATA_LOCALSTORAGE_KEY)
      })

      this.survey.onPartialSend.add((survey: SurveyModelType) => {
        this.sendDataToServer(uuid!, survey, false)

        // Save partial data to localStorage
        const data = survey.data
        data.pageNo = survey.currentPageNo
        localStorage.setItem(DATA_LOCALSTORAGE_KEY, JSON.stringify(data))
      })
    }
  }

  get cssClasses() {
    const settings = this.surveyData.settings

    if (!settings) return {}

    return {
      'hide-progress-text': !settings.show_progress_text
    }
  }

  get cssVars() {
    const settings = this.surveyData.settings

    if (!settings) return {}

    return {
      '--survey-backgroud-color': settings.background_color
    }
  }

  scrollToTop() {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  sendDataToServer(uuid: string, survey: any, isComplete = false) {
    responseModule.actionSendResponse({
      surveyId: this.surveyData.id!,
      responseId: uuid,
      responseData: survey.data,
      isComplete: isComplete
    })
  }
}
